<template>
  <div>
    <a-modal
      width="1300px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="维护家属告知书"
      @ok="handleSubmit"
    >

      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 200 }"
        @submit="handleSubmit"
      >

        <a-tabs v-model="activeKey">
          <a-tab-pane key="extraContent" tab="套餐以外收费项目">
            <a-form-item>
              <wang-editor-frequently-asked-question
                v-decorator="['extra_content', {
                  rules: [
                    { max: 3000, message: '最多3000个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane key="buddhistNotes" tab="佛事注意事项" force-render>
            <a-form-item>
              <wang-editor-frequently-asked-question
                v-decorator="['buddhist_notes', {
                  rules: [
                    { max: 3000, message: '最多3000个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-tab-pane>
        </a-tabs>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  findExtraContentInfoAndBuddhistNotesInfo,
  updateExtraContentInfoAndBuddhistNotesInfo
} from '@/api/package_management'
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'

export default {
  name: 'MaintainFamilyNotification',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'extra_content_and_buddhist_notes_edit' }),
      submitting: false,
      data: {},
      activeKey: 'extraContent'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      // 访问编辑框的时候加载以前保存的内容
      findExtraContentInfoAndBuddhistNotesInfo(this.record.id).then((res) => {
        this.form.setFieldsValue({
          extra_content: res.data.extra_content,
          buddhist_notes: res.data.buddhist_notes
        })
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateExtraContentInfoAndBuddhistNotesInfo(this.record.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
